<template>
  <div>
    
    <v-container class="py-10" id="regular-tables" fluid tag="section">
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading" v-if="!loading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
          </tr>
        </thead>
        <div class="table__spinnerWrapper" v-if="loading">
          <app-spinner></app-spinner>
        </div>
        <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
          <template>
            <tr style="cursor: pointer" v-for="item in boxes" :key="item.id" @click="goToCashierBoxStatement(item)">
              <td>{{ item.id }}</td>
              <td>{{ item.box.name }}</td>
              <td>{{ item.user.username }}</td>
            </tr>
          </template>
        </v-fade-transition>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import spinner from "../../../../components/base/spinner.vue";
import axios from "axios";
export default {
  components: {
    appSpinner: spinner,
  },
  data() {
    return {
      tableHeaders: ["id", "box name", "user name"],
      boxes: [],
      totalTablePages: null,
      loading: false,
    };
  },
  methods: {
    goToCashierBoxStatement(item) {
      if (this.returnAbility("cashier-box:statement")) {
        this.$router.push(`cashierBoxStatement/${item.box.id}`);
      }
    },
    async fechBoxes() {
      try {
        if (this.returnAbility("cashier-box:show")) {
          this.loading = true;
          const boxes = await axios.get("/accounting/cashier-box/my-box");
          this.boxes = boxes.data.data;
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fechBoxes();
  },
};
</script>

<style>

</style>